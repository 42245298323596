.banner {
    background: #faa88e;
    width: 100%;
    top: 0;
    margin-top: 0;
}

.banner p {
    font-size: 16px;
    text-align: center;
    margin: 0;
}