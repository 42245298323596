.About-content {
    margin: 0 auto;
    max-width: 960px;
}

.About-image {
    margin-bottom: 40px;
    z-index: -1;
}

.blm-banner{
    background: #000000;
}

.blm-link {
    margin-bottom: 0;
    text-align: center;
}

.blm-link a {
    text-decoration: none;
    padding: 20px 15px;
    color: #ffffff;
    transition: opacity ease 0.2s;
}

.blm-link a:hover {
    opacity: 0.7;
}

@media screen and (max-width: 720px) {
    .About-image {
        float: none;
        margin: 20px auto;
    }

    .About-content {
        padding: 0 20px;
    }
  }