.Hero {
    height: 400px;
    width: 100%;
    background-size: cover;
    display: flex;
    align-items: center;
}

.call-out-wrapper {
    position: relative;
    margin: 0 auto;
    width: 1160px;
}

.call-out {
    margin: 40px;
    color: #ffffff;
    width: 50%;
}

.call-out p {
    font-family: 'Work Sans', sans-serif;
    margin: 10px 0;
    font-size: 32px;
    line-height: 40px;
    padding-bottom: 20px;
    text-shadow: 2px 2px 8px black;
}

.call-out a {
    background: #faa88e;
    color: #000;
    right: 0;
    top: 86px;
    font-size: 16px;
    padding: 10px 15px;
    text-decoration: none;
    transition: color ease 0.2s ;
}

.call-out a:hover {
    color: rgb(104, 104, 104);
}
