.Post-index-wrapper {
    margin: 50px auto;
    padding: 0 40px;
    max-width: 1160px;
}

.Post-index {
    display: flex;
    flex-wrap: wrap;
}

.Post-item {
    max-width: 50%;
    margin: 0 auto 50px auto;
    padding: 0 10px;
}

.Post-item h2 {
    font-size: 24px;
    margin: 10px 0;
}

.Post-item p {
    margin: 10px 0;
    font-size: 18px;
}

.Post-item a {
    color: #000;
    text-decoration: none;
    font-size: 16px;
    transition: color ease 0.2s;
}

.Post-item a:hover {
    color: rgb(104, 104, 104);
}

@media screen and (max-width: 600px) {
    .Post-item {
        max-width: 100%;
        margin-bottom: 50px;
        padding: 0 10px;
    }
}