.Header {
    font-family: 'Work Sans', sans-serif;
    background-size: cover;
    margin: 0 auto;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1160px;
}

.Header h1 {
    font-size: 48px;
}

.Header a {
    font-family: 'Work Sans', sans-serif;
    color: rgba(49, 49, 49, 0.7);
    text-decoration: none;
    transition: color 0.2s ease;
}

.Header a:hover {
    color: rgba(74, 254, 199, 1.00);
}

.Header p {
    margin: 0 auto;
    color: rgba(72, 72, 72, 0.7);
    max-width: 800px;
    width: 85%;
    text-align: center;
    padding-bottom: 40px;
}

.Header__logo {
    background-repeat: no-repeat;
    background-size: cover;
    width: 250px;
    display: inline-block;
    padding: 25px 0;
}

.Header__container {
    margin: 0 auto;
    max-width: 960px;
    min-height: 30vh;
    padding: 1rem 1.0875rem;
}

.Header__title-content {
    margin-top: 40vh;
}