.BodyText {
    font-family: sans-serif;
    font-size: 16px;
    margin: 0 auto;
    max-width: 700px;
}

.HeaderText {
    font-weight: 700;
    font-family: sans-serif;
    font-size: 16px;
    margin: 10px auto;
    max-width: 700px;
}

.ImageSlice {
    margin: 0 auto;
    max-width: 800px;
}

.ImageSlice img {
    margin: 0;
}

.ImageSlice p {
    margin: 0 auto 20px auto;
    max-width: 700px;
    font-style: italic;
    font-size: 16px;
}

.EmbedSlice {
    margin: 0 auto;
    max-width: 800px;
}

.EmbedSlice embed {
    width: 100%;
}