.Post-wrapper {
    margin: 50px auto;
    max-width: 960px;
    width: 90%;
}

.Post-wrapper h1 {
    font-size: 36px;
    margin: 30px 0 10px;
}

.Post-category {
    margin: 20px auto;
    max-width: 800px;
}

.Post-feature-image {
    margin: 0 auto;
    max-width: 800px;
}

.Post-feature-content {
    margin: 0 auto;
    max-width: 700px;
}
