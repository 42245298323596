@import url('https://fonts.googleapis.com/css?family=Work+Sans:400,700&display=swap');

.Section__header {
  font-size: 16px;
  text-transform: uppercase;
}

.Section__index {
  width: 80%;
}

.Post__container {
  margin: 0;
  list-style: none;
}

.Post__category {
  text-transform: uppercase;
  color: #808080;
  display: inline-block;
  font-size: 15px;
  border-bottom: 1px solid;
}

iframe {
  width: 100%;
}

.Post {
  margin-bottom: 2em;
}

.Post h1 {
  font-family: 'Work Sans', sans-serif;
}

.Post h2 {
  font-size: 32px;
  font-family: 'Work Sans', serif;
  font-weight: '700';
  margin: 0.5em 0 1em 0;
}

.Post a {
  color: rgba(83, 78, 72, 1.00);
  text-decoration: none;
}

.Post p {
  font-family: 'Work Sans', sans-serif;
}

.Post a:hover {
  color:#808080;
  transition: 200ms ease;
}

.Post__image {
  height: 300px;
  width: 400px;
  overflow: hidden;
  text-indent: -140px;
  margin-bottom: 2em;
}

.Post__image img {
  max-width: initial;
  transform: translateY(-30%);
}

blockquote {
  font-size: 28px;
  font-style: italic;
  font-family: 'Playfair Display', sans-serif;
  color: #484848;
  border-left: 5px solid #bdbdbb;
  padding: 20px;
}

footer {
  margin: 0 auto;
  text-align: center;
  width: 85%;
  max-width: 800px;
}
